<template>
  <div>
    <div class="shon_banner">
      <div class="container">
        <h1>{{ $t("publishHouse.title") }}</h1>
        <div class="shon_box">
          <div class="publish-icon"></div>
          <p v-html="$t('publishHouse.text')"></p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="projects">
        <h2 class="section_title">{{ $t("publishHouse.title1") }}</h2>
        <div class="projects__list">
          <a
            class="content"
            href="https://brill.com/view/title/32479#:~:text=In%20Qazaql%C3%AFq%2C%20or%20Ambitious%20Brigandage,widespread%20among%20the%20Turko%2DMongolian"
          >
            <p v-html="$t('publishHouse.text1')"></p>
          </a>
          <a class="content">
            <p v-html="$t('publishHouse.text2')"></p>
          </a>
          <a class="content">
            <p v-html="$t('publishHouse.text3')"></p>
          </a>
          <a
            class="content"
            href="https://www.routledge.com/Soviet-Orientalism-and-the-Creation-of-Central-Asian-Nations/Bustanov/p/book/9780815365723"
          >
            <p v-html="$t('publishHouse.text4')"></p>
          </a>
          <a
            class="content"
            href="https://www.ozon.ru/product/sovetskiy-proekt-v-kazahstane-vlast-i-etnichnost-1920-1930-e-gg-167666914/?sh=nlvYVEsYxg"
          >
            <p v-html="$t('publishHouse.text5')"></p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
a {
  color: #e66e00;
  text-decoration: none;

  position: relative;
  padding: 10px;

  &,
  &:before {
    transition: all 0.5s;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0%;
    right: 0;
    width: 0%;
    height: 100%;
    background: #e66e00;
    transition: width 0.5s;
    z-index: -1;
  }

  &:hover {
    color: black;

    &:before {
      width: 100%;
      right: auto;
      left: 0;
    }
  }
}
.content {
  padding-top: 10px;
  font-size: 20px;
  line-height: 23px;
  color: #202020;
  position: relative;
  text-decoration: none;
}
.publish-icon {
  width: 117px;
  height: 117px;
  background-image: url("../assets/icons/publish-icon.svg");
  background-repeat: no-repeat;
  background-color: #00303a;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  background-position: center;
  position: absolute;
}
.shon_box {
  padding: 65px 45px 40px 45px;
  position: relative;
  max-width: 612px;
  background: #00303a;
  margin: 90px auto;
  border-radius: 5px;
  font-size: 20px;
  line-height: 23px;
  &::after {
    content: "";
    width: 20px;
    display: block;
    position: absolute;
    width: 389px;
    height: 43px;
    background: #e66e00;
    border-radius: 2px;
    bottom: -30px;
    left: -50px;
  }
}
.shon_banner {
  color: #ffffff;
  text-align: center;
  padding: 50px 0;
  background-size: cover;
  height: 870px;
  background-image: url("../assets/images/publish-banner.png");
  background-repeat: no-repeat;
  background-position: center;
  h1 {
    font-size: 35px;
    line-height: 41px;
    padding-bottom: 20px;
    border-bottom: 2px solid #ffffff;
  }
}
@media (max-width: 768px) {
  .shon_box {
    font-size: 16px;
    line-height: 23px;
    &::after {
      height: 18px;
      bottom: 0;
    }
  }
  .shon_banner {
    padding: 120px 0 50px 0;
    height: 1200px;
    background-size: auto;
    h1 {
      font-size: 25px;
      padding-bottom: 10px;
    }
  }
  .projects {
    margin: 50px 0;
    &__list {
      display: flex;
      margin-top: 36px;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
    }
    &__item {
      max-width: 560px;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      padding: 30px;
    }
    &__info {
      color: #ffffff;
      align-self: flex-end;
      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
      }
      p {
        font-size: 18px;
        line-height: 21px;
        font-weight: 300;
        margin-top: 20px;
      }
    }
  }
  .section_title {
    font-size: 18px;
    line-height: 18px;
  }
  .section_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 2px solid #e66e00;
    color: #202020;
  }
}
</style>
